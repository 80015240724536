<template>
  <v-app>
    <slot>
      <v-overlay v-model="baseStore.state.value.overlay" />
    </slot>
    <div
      v-if="baseStore.state.value.overlay"
      class="loading"
    >
      <v-progress-circular
        :size="50"
        indeterminate
        color="primary"
      />
    </div>
  </v-app>
</template>

<script lang="ts">
import { useBaseStore } from '@/composables/base-store';
export default defineComponent({
  setup() {
    const baseStore = useBaseStore();

    return {
      baseStore
    };
  }
});
</script>
